<template>
  <LayoutDefault>
    <!-- Content Wrapper. Contains page content -->
    <Loading :active="loading" />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Tickets</h1>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-sm-6">
              <!-- <button type="button" class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0">Importar</button> -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <!-- <div class="card-header">
                <h3 class="card-title">Folders</h3>
              </div> -->
              <!-- <button @click="fakeCreateTest()">Save Fake</button> -->
              <div class="card-body p-0">
                <ul class="nav nav-pills flex-column">
                  <li
                    class="nav-item"
                    :class="ticketsStatus === 'Todos' ? 'active' : ''"
                  >
                    <a @click="filterData('all')" class="nav-link">
                      <i class="far fa-comment"></i>
                      Todos
                      <span class="badge bg-primary float-right">{{
                        all
                      }}</span>
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    :class="ticketsStatus === 'Abiertos' ? 'active' : ''"
                  >
                    <a @click="filterData('Abiertos')" class="nav-link">
                      <i class="far fa-comments"></i>
                      Abiertos
                      <span class="badge bg-primary float-right">{{
                        open
                      }}</span>
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    :class="ticketsStatus === 'En proceso' ? 'active' : ''"
                  >
                    <a @click="filterData('En proceso')" class="nav-link">
                      <i class="far fa-comment-dots"></i>
                      En proceso
                      <span class="badge bg-primary float-right">{{
                        inProcess
                      }}</span>
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    :class="ticketsStatus === 'Cerrados' ? 'active' : ''"
                  >
                    <a @click="filterData('Cerrados')" class="nav-link">
                      <i class="fas fa-check-double"></i>
                      Cerrados
                      <span class="badge bg-primary float-right">{{
                        closed
                      }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <!-- /.col -->
          <div class="col-md-9">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h3 class="card-title">{{ ticketsStatus }}</h3>

                <!-- <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="Buscar ticket">
                    <div class="input-group-append">
                      <div class="btn btn-primary">
                        <i class="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- /.card-tools -->
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <div class="table-responsive mailbox-messages">
                  <table id="tickets" class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Editar</th>
                        <th>ID Ticket</th>
                        <th>Area</th>
                        <th>Asunto</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                  <!-- /.table -->
                </div>
                <!-- /.mail-box-messages -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import Loading from "../components/Loading";
import $ from "jquery";
import "datatables.net";
import TicketsService from "../services/ticketsService";
import ticketsService from "../services/ticketsService";
export default {
  name: `Tickets`,
  data() {
    return {
      table: {},
      tickets: [],
      ticketsStatus: "Todos",
      all: 0,
      open: 0,
      inProcess: 0,
      closed: 0,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.table = $("#tickets").DataTable({
      pageLength: 50,
      language: {
        url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json",
      },
    });
    const res = await TicketsService.getAllTickets();
    if (res && res.response) {
      this.tickets = res.response;
      if (res.response.data) {
        this.tickets = res.response.data;
      }
    }
    await this.countData();
    this.loading = false;
  },
  methods: {
    addDataToTable(data) {
      this.table.clear();
      data.forEach((el) => {
        this.table.row
          .add([
            `<a href='tickets/edit/${el.id}' >Editar</a>`,
            el.id,
            el.area,
            el.subject,
            el.status,
          ])
          .draw(false);
      });
    },
    filterData(status) {
      if (status !== "all") {
        const filterTickets = this.tickets.filter(
          (ticket) =>
            ticket.status.toLowerCase().replace(" ", "") ===
            status.toLowerCase().replace(" ", "").replace(/s\s*$/, "")
        );
        this.ticketsStatus = status;
        this.addDataToTable(filterTickets);
        return;
      }
      this.ticketsStatus = "Todos";
      this.addDataToTable(this.tickets);
    },
    countData() {
      this.all = this.tickets.length;
      this.open = this.tickets.filter(
        (el) => el.status.toLowerCase() === "abierto"
      ).length;
      this.closed = this.tickets.filter(
        (el) => el.status.toLowerCase() === "cerrado"
      ).length;
      this.inProcess = this.tickets.filter(
        (el) => el.status.toLowerCase().replace(" ", "") === "enproceso"
      ).length;
      this.filterData("all");
    },
    async fakeCreateTest() {
      const comments = [
        { comment: "hello", userId: 1 },
        { comment: "hello2", userId: 2 },
      ];
      const data = {
        area: "test",
        subject: "test",
        detail: JSON.stringify(comments),
        user_id: 2,
        status: "En Proceso",
      };
      await ticketsService.saveTicket(data);
    },
  },
  components: {
    LayoutDefault,
    Loading
  },
};
</script>
<style lang="scss" scoped>
.dataTables_length,
.dataTables_filter {
  padding: 0.8em 1em;
}
.card-primary.card-outline {
  border-top: 3px solid #ff6900;
}
.nav-pills .nav-link:not(.active):hover {
  color: #ff6900;
}
.nav-pills .nav-item.active .nav-link {
  color: #ff6900;
}
.nav-pills {
  .nav-link {
    cursor: pointer;
  }
  .badge {
    background-color: #ff6900 !important;
  }
}
table {
  td {
    a {
      color: #ff6900;
    }
  }
}
</style>
